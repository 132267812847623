import React, { useState } from "react"
import { Outlet } from "react-router-dom"
import { useSelector } from "react-redux"
import styled from "styled-components"
import SearchHeader from "views/Panel/Search/SearchHeader"
import PanelContentLayout from "components/layout/PanelContentLayout"
import { RootState } from "store/store"
import SearchContent from "views/Panel/Search/SearchContent"
import { Box } from "components/atoms/_atoms"
import { AISourceDocument, AISearchAnswerResponse } from "services/AIService"
import SearchDataTabs from "views/Panel/Search/SearchDataTabs"
import DocumentSignatureContent from "views/Panel/Search/content/DocumentSignatureContent"
import { DocumentSignatureService } from "services/DocumentSignatureService"
import { useCustomQuery } from "hooks/useCustomQuery"
import SummaryBlock from "components/shared/blocks/SummaryBlock"
import { useOnboardingSearch } from "hooks/useOnboardingSearch"
import SearchAnswer from "views/Panel/Search/SearchAnswer"
import Button from "components/atoms/button"

const Profile = () => {
  const {
    message: saveMessage,
    answer: saveAnswer,
    data: saveData,
  } = useSelector((state: RootState) => state.search)

  const [loading, setLoading] = useState(false)
  const [answer, setAnswer] = useState<null | AISearchAnswerResponse>(saveAnswer)
  const [data, setData] = useState<AISourceDocument[] | null>(saveData)
  const [tab, setTab] = useState("all")

  const { isLoading: countLoading, data: count } = useCustomQuery<number>({ key: ["count"] }, () =>
    DocumentSignatureService.count(),
  )

  const hasAnswer = !!answer && !loading

  useOnboardingSearch(data)

  return (
    <>
      <Wrapper>
        <PanelContentLayout
          icon="search"
          title="Znajdź sygnaturę"
          // text={
          //   countLoading || !count ? (
          //     <LoaderBlock height="22px" />
          //   ) : (
          //     <P margin="0">
          //       Szukaj spośrod{" "}
          //       <span>
          //         {Intl.NumberFormat("pl-PL", {
          //           style: "decimal",
          //           maximumFractionDigits: 0,
          //         }).format(count)}
          //       </span>{" "}
          //       dokumentów przy użyciu sztucznej inteligencji
          //     </P>
          //   )
          // }
        >
          <SearchHeader
            loading={loading}
            setLoading={setLoading}
            setAnswer={setAnswer}
            setTab={setTab}
            setData={setData}
          />
          <SearchDataTabs data={data} tab={tab} setTab={setTab} />
          <Box marginTop="M">
            <SearchContentWithAnswer hasAnswer={hasAnswer}>
              <SearchContent
                loading={loading}
                data={data?.filter(el => {
                  if (tab === "all") return true

                  return el.question_code === tab
                })}
              />
              <SearchAnswer loading={loading} answer={answer} />
            </SearchContentWithAnswer>
          </Box>
        </PanelContentLayout>
      </Wrapper>
      <Outlet />
    </>
  )
}

export default Profile

const SearchContentWithAnswer = styled(Box)<{
  hasAnswer?: boolean
}>`
  display: grid;
  grid-template-columns: ${({ hasAnswer }) => (hasAnswer ? "3fr 1fr" : "1fr")};
  grid-gap: ${({ theme }) => theme.space.S};
`

const Wrapper = styled(Box)`
  display: flex;
`
