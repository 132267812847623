import React, { useCallback, useState } from "react"
import styled, { css } from "styled-components"

import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { ChatRow } from "components/shared/Documents/components/DocumentChat"
import { LocalStorageService } from "services"
import { Label } from "components/atoms/typography"
import Button, { IconButton } from "components/atoms/button"
import { logout } from "store/auth"
import { initProfileState } from "store/profile"
import Link from "components/atoms/link"
import Logo from "components/atoms/logo"
import { RootState } from "store/store"

import { Box, Absolute } from "components/atoms/_atoms"
import NPSRatingModal from "components/modals/NPSRatingModal"
import NPSFeatureRequestModal from "components/modals/NPSFeatureRequestModal"
import { updateShowMenu } from "store/ui"

const PanelNavigationLayout = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { showMenu } = useSelector((state: RootState) => state.ui)
  const { user } = useSelector((state: RootState) => state.auth)
  const { profile } = useSelector((state: RootState) => state.profile)

  const [modal, setModal] = useState<string | boolean>(false)

  const handleLogout = useCallback(() => {
    LocalStorageService.removeObject("token")
    dispatch(logout())
    dispatch(initProfileState())
  }, [])

  const handleMenuToggle = () => {
    dispatch(updateShowMenu(!showMenu))
  }

  return (
    <>
      <Wrapper showMenu={showMenu}>
        <Content>
          <LogoWrapper showMenu={showMenu}>
            <Logo />
          </LogoWrapper>
          <NavigationWrapper>
            <Link
              type="secondary"
              background="none"
              icon="search"
              to="/panel/search"
              justifyContent="flex-start"
              marginBottom="XXS"
              contentStyles={{
                minWidth: "max-content",
              }}
            >
              Znajdź sygnaturę
            </Link>
            <Link
              type="secondary"
              background="none"
              icon="clock"
              to="/panel/history"
              justifyContent="flex-start"
              marginBottom="XXS"
              contentStyles={{
                minWidth: "max-content",
              }}
            >
              Historia wyszukiwań
            </Link>
            {/*<Link*/}
            {/*  type="secondary"*/}
            {/*  background="none"*/}
            {/*  icon="time"*/}
            {/*  to="/panel/history"*/}
            {/*  justifyContent="flex-start"*/}
            {/*  marginBottom="XXS"*/}
            {/*>*/}
            {/*  Historia wyszukiwania*/}
            {/*</Link>*/}
            <Link
              type="secondary"
              background="none"
              icon="heart"
              to="/panel/favourites"
              justifyContent="flex-start"
              marginBottom="XXS"
              contentStyles={{
                minWidth: "max-content",
              }}
            >
              Ulubione dokumenty
            </Link>
            <Link
              type="secondary"
              background="none"
              icon="settings"
              to="/panel/settings"
              justifyContent="flex-start"
              marginBottom="XXS"
              contentStyles={{
                minWidth: "max-content",
              }}
            >
              Ustawienia
            </Link>
          </NavigationWrapper>
          <BottomWrapper>
            <Box marginBottom="M" display={showMenu ? "block" : "none"}>
              <Button
                type="third"
                icon="heart"
                color="third"
                padding="S"
                paddingTop="XS"
                paddingBottom="XS"
                onClick={() => setModal("rating")}
              >
                Oceń aplikację
              </Button>
              <Button
                type="third"
                icon="lightbulb"
                color="third"
                padding="S"
                paddingTop="XS"
                paddingBottom="XS"
                onClick={() => setModal("feature-request")}
              >
                Masz pomysł na rozwój?
              </Button>
            </Box>
            {showMenu ? (
              <StyledChatRow text="Zadaj dowolne pytanie związane z prawem" />
            ) : (
              <IconButton
                icon="logotype"
                size="45px"
                iconSize="L"
                borderRadius="borderRadius"
                color="white"
                background="backgroundGradient"
                onClick={() => navigate("/panel/chat")}
              />
            )}
            {showMenu ? (
              <ProfileWrapper showMenu={showMenu}>
                <ProfileContent>
                  <Label margin="0" marginBottom="XXS">{`${
                    profile?.name && profile?.name.length > 25
                      ? `${profile?.name.substring(0, 25)}...`
                      : profile?.name
                  }`}</Label>
                  <Label type="third" color="third">
                    {user?.firstName} {user?.lastName}
                  </Label>
                </ProfileContent>
                <IconButton icon="logout" onClick={handleLogout} />
              </ProfileWrapper>
            ) : (
              <IconButton
                icon="logout"
                size="45px"
                borderRadius="borderRadius"
                background="background"
                onClick={handleLogout}
                marginTop="M"
              />
            )}
          </BottomWrapper>
        </Content>
        <StyledAbsolute right="0" top="S">
          <StyledIconButton
            icon={showMenu ? "arrow-left" : "arrow-right"}
            onClick={handleMenuToggle}
            background="white"
            borderRadius={5}
          />
        </StyledAbsolute>
      </Wrapper>
      {modal === "rating" && <NPSRatingModal show={true} setShow={setModal} />}
      {modal === "feature-request" && <NPSFeatureRequestModal show={true} setShow={setModal} />}
    </>
  )
}

export default PanelNavigationLayout

const StyledIconButton = styled(IconButton)`
  border: 1px solid ${({ theme }) => theme.color.backgroundDarker};
`

const StyledChatRow = styled(ChatRow)`
  min-width: calc(300px - ${({ theme }) => theme.space.S} * 2);
`

const StyledAbsolute = styled(Absolute)`
  transform: translateX(50%);
  z-index: 1;
`

const BottomWrapper = styled.div`
  min-width: calc(300px - ${({ theme }) => theme.space.S} * 2);
`

const NavigationWrapper = styled.div`
  flex: 1 1;
`

const ProfileContent = styled.div`
  //display: flex;
  //align-items: center;
  flex: 1 1;
`

const ProfileWrapper = styled.div<{
  showMenu?: boolean
}>`
  display: flex;
  align-items: center;
  background: ${({ theme }) => theme.color.background};
  border-radius: ${({ theme }) => theme.variable.borderRadius};
  padding: ${({ theme }) => theme.space.S};
  margin-top: ${({ theme }) => theme.space.M};

  ${({ showMenu }) =>
    showMenu &&
    css`
      min-width: calc(300px - ${({ theme }) => theme.space.S} * 2);
    `};
`

const LogoWrapper = styled.div<{
  showMenu?: boolean
}>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: ${({ theme }) => theme.space.M} 0;
  height: 35px;
  min-width: calc(300px - ${({ theme }) => theme.space.S} * 2);
  position: relative;

  ${({ showMenu }) =>
    showMenu
      ? css`
          svg {
            .text {
              opacity: 1;
            }
          }
        `
      : css`
          svg {
            .text {
              opacity: 0;
            }
          }
        `};

  svg {
    //position: absolute;
    //left: 5px;
    //top: 0;
    height: 35px;
    width: auto;
    margin: 0 5px;
    //width: 100%;

    .text {
      transition: opacity 0.3s;
    }
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  padding: ${({ theme }) => theme.space.S};
  width: 100%;
  height: 100%;
  overflow: hidden;
`

const Wrapper = styled.div<{
  showMenu?: boolean
}>`
  position: relative;
  width: 100%;
  max-width: ${({ theme, showMenu }) =>
    showMenu ? "300px" : `calc(${theme.space.S} * 4 + ${theme.font.M})`};
  transition: max-width 0.3s;
`
