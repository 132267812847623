import React from "react"
import styled from "styled-components"
import Button from "components/atoms/button"
import { AISourceType } from "services/AIService"
import { ButtonType } from "components/atoms/button/Button"

const ChatMessageSource = ({
  type,
  data,
  onClick = () => {},
}: {
  type?: ButtonType
  data: AISourceType
  onClick: (e?: AISourceType) => void
}) => {
  if (data.metadata.type === "act") {
    const { id, title, artStr, parStr } = data.metadata

    return (
      <>
        <Button styledType={type} onClick={onClick} key={id} type="secondary" tooltip={title}>
          {`${artStr}${parStr ? ` ${parStr}` : ""}`}
        </Button>
      </>
    )
  }

  return <></>
}

export default ChatMessageSource
