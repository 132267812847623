import React, { ReactNode, useState } from "react"
import styled, { css } from "styled-components"
import { MessageType } from "./types"
import Icon from "components/atoms/icon"
import { P, Label } from "components/atoms/typography"
import { Box } from "components/atoms/_atoms"
import { BaseStyles } from "types/base-styles"
import ChatMessageSource from "components/molecules/chat/ChatMessageSource"
import { AISourceType } from "services/AIService"
import MessageSourceModal from "components/modals/MessageSourceModal"

export const MessageLoading = ({
  color = "second",
  small = false,
}: {
  color?: string
  small?: boolean
}) => {
  return (
    <TypingWrapper color={color} small={small}>
      <div className="dot"></div>
      <div className="dot"></div>
      <div className="dot"></div>
    </TypingWrapper>
  )
}

export const ChatMessageLoading = () => {
  return (
    <ChatMessage type="ai">
      <MessageLoading />
    </ChatMessage>
  )
}

export const ChatMessage = ({
  type,
  message,
  question,
  children,
  sources,
  messageBackground,
  ...rest
}: Omit<MessageType, "id" | "message"> & {
  children?: ReactNode
  message?: string
  question?: string
  messageBackground?: string
} & BaseStyles) => {
  const [showSource, setShowSource] = useState<null | AISourceType>(null)

  if (type === "system") {
    return (
      <SystemWrapper>
        <Label type="secondary" color="second" textAlign="center">
          {message}
        </Label>
      </SystemWrapper>
    )
  }

  return (
    <>
      <MessageWrapper>
        <MessageContent type={type} {...rest}>
          {type === "ai" && <Icon icon="logotype" size="L" color="primary" />}
          <div>
            {message ? (
              <P fontSize="S" width="100%" color={type === "ai" ? "secondary" : "white"} margin="0">
                {message}
              </P>
            ) : (
              children
            )}
          </div>
        </MessageContent>
        {sources && sources.length > 0 && (
          <MessageSources>
            {sources.map((source, index) => (
              <ChatMessageSource
                type="secondary"
                key={`${source.metadata.id}-${index}`}
                data={source}
                onClick={() => setShowSource(source)}
              />
            ))}
          </MessageSources>
        )}
      </MessageWrapper>
      <MessageSourceModal data={showSource} setState={setShowSource} />
    </>
  )
}

export default ChatMessage

const TypingWrapper = styled(Box)<{
  color: string
  small: boolean
}>`
  align-items: center;
  display: flex;
  height: 17px;

  .dot {
    animation: mercuryTypingAnimation 1.8s infinite ease-in-out;
    background-color: ${({ theme, color }) => theme.color[color]};
    border-radius: 50%;
    height: 5px;
    margin-right: 4px;
    vertical-align: middle;
    width: 5px;
    display: inline-block;
  }

  .dot:nth-child(1) {
    animation-delay: 200ms;
  }

  .dot:nth-child(2) {
    animation-delay: 300ms;
  }

  .dot:nth-child(3) {
    animation-delay: 400ms;
  }

  .dot:last-child {
    margin-right: 0;
  }

  ${({ small }) =>
    small &&
    css`
      .dot {
        height: 3px;
        width: 3px;
      }
    `};

  @keyframes mercuryTypingAnimation {
    0% {
      transform: translateY(0px);
      opacity: 0.7;
    }
    28% {
      transform: translateY(-7px);
      opacity: 1;
    }
    44% {
      transform: translateY(0px);
      opacity: 0.7;
    }
  }
`

const SystemWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: ${({ theme }) => theme.space.S};
  padding-bottom: ${({ theme }) => theme.space.S};
  position: relative;

  label {
    background: white;
    z-index: 1;
    padding: 0 ${({ theme }) => theme.space.S};
  }

  &:before {
    content: "";
    width: 100%;
    position: absolute;
    top: 50%;
    height: 1px;
    background: ${({ theme }) => theme.color.border};
  }
`

const MessageWrapper = styled(Box)``

const MessageSources = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  grid-gap: ${({ theme }) => theme.space.S};
  margin-top: ${({ theme }) => theme.space.S};
`

const MessageContent = styled(Box)<{
  type: "ai" | "human" | "system"
}>`
  padding: ${({ theme }) => theme.space.S};
  border-radius: ${({ theme }) => theme.variable.borderRadius};
  //box-shadow: ${({ theme }) => theme.variable.boxShadow};
  grid-gap: ${({ theme }) => theme.space.XS};
  display: flex;
  width: max-content;
  max-width: 90%;
  border: 1px solid white;

  ${({ type }) =>
    type === "ai" &&
    css`
      background: ${({ theme }) => theme.color.background};
      border-color: ${({ theme }) => theme.color.backgroundDarker};
      margin-right: auto;
    `}

  ${({ type }) =>
    type === "human" &&
    css`
      background: ${({ theme }) => theme.color.primary};
      border-color: ${({ theme }) => theme.color.primary};
      margin-left: auto;
      justify-content: flex-end;
    `}
`
