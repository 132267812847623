import React from "react"
import styled from "styled-components"
import { Box, Row } from "components/atoms/_atoms"
import LoaderBlock from "components/shared/blocks/LoaderBlock"
import SignatureBlock from "components/shared/Documents/Signature/blocks/SignatureBlock"
import { AISourceDocument } from "services/AIService"
import { P } from "components/atoms/typography"
import FunFactBlock from "components/shared/blocks/FunFactBlock"
import Button from "components/atoms/button"

const SearchContent = ({
  loading,
  data,
}: {
  loading: boolean
  data?: AISourceDocument[] | null
}) => {
  const onNextPage = () => {
    const btn = document.querySelector("#search_form_submit") as HTMLElement
    if (btn) {
      btn.click()
    }
  }

  return (
    <Wrapper>
      {!loading && data && data.length > 0 && (
        <>
          {data
            .filter(function (item, pos) {
              return data?.findIndex(el => el.metadata.id === item.metadata.id) == pos
            })
            .map((document, index) => (
              <SignatureBlock key={index} suggested={index === 0} data={document} />
            ))}
        </>
      )}

      {loading && (
        <>
          <FunFactBlock />
          {/*<LoaderBlock height="150px" />*/}
          {/*<LoaderBlock height="150px" />*/}
          {/*<LoaderBlock height="150px" />*/}
        </>
      )}
      {!loading && data?.length === 0 && (
        <Row marginTop="M" justifyContent="center">
          <P small>Brak dokumentów spełniających kryteria</P>
        </Row>
      )}
      {!loading && !!data && (
        <Button
          type="secondary"
          background="backgroundDarker"
          width="100%"
          marginTop="M"
          marginBottom="M"
          onClick={onNextPage}
        >
          Szukaj dalej
        </Button>
      )}
    </Wrapper>
  )
}

export default SearchContent

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  grid-gap: ${({ theme }) => theme.space.XS};
  padding-bottom: ${({ theme }) => theme.space.M};
`
