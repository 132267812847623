"use client"

import React, { ReactNode } from "react"
import styled, { css } from "styled-components"
import { Absolute, common, Row, Box } from "components/atoms/_atoms"
import Icon from "components/atoms/icon"
import Spinner from "components/atoms/spinner"
import { BaseStyles } from "types/base-styles"
import { Tooltip } from "@mui/material"

export type ButtonType = "primary" | "secondary" | "third" | "fourth" | "primaryBackground"

export type ButtonProps = StyledButtonProps & {
  children: ReactNode
  type?: "primary" | "secondary" | "third"
  icon?: string
  submit?: boolean
  onClick?: (e?: any) => void
  ariaLabel?: string
  hoverColor?: string
  hoverBackground?: string
  padding?: string
  boxShadow?: boolean
}

export type StyledButtonProps = BaseStyles & {
  type?: ButtonType
  styledType?: ButtonType
  id?: string
  icon?: string
  submit?: boolean
  onClick?: (e?: any) => void
  loading?: boolean
  disabled?: boolean
  hoverColor?: string
  tooltip?: string
  hoverBackground?: string
  padding?: string
  form?: string
  boxShadow?: boolean
  contentStyles?: BaseStyles
}

const Button = ({
  children,
  type = "primary",
  id,
  icon,
  submit,
  onClick = () => {},
  loading,
  ariaLabel,
  disabled,
  padding,
  hoverColor,
  hoverBackground,
  form,
  boxShadow = false,
  contentStyles,
  tooltip,
  ...rest
}: ButtonProps) => {
  return (
    <StyledButton
      id={id}
      aria-label={ariaLabel ?? children}
      styledType={type}
      // @ts-ignore
      type={submit ? "submit" : "button"}
      onClick={e => onClick(e)}
      disabled={disabled}
      loading={loading}
      padding={padding}
      hoverColor={hoverColor}
      hoverBackground={hoverBackground}
      boxShadow={boxShadow}
      form={form}
      {...rest}
    >
      <Row alignItems="center" opacity={loading ? 0 : 1} {...contentStyles}>
        {icon && (
          <Row alignItems="center" marginRight="S">
            <Icon icon={icon} />
          </Row>
        )}
        {children}
        {tooltip && (
          <Tooltip title={tooltip}>
            <Row alignItems="center" marginLeft="S">
              <Icon icon="info-circle" />
            </Row>
          </Tooltip>
        )}
      </Row>
      {loading && (
        <Absolute
          width="100%"
          height="100%"
          left="0"
          top="0"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Spinner color={type === "primary" ? "white" : "primary"} size="20px" />
        </Absolute>
      )}
    </StyledButton>
  )
}

export default Button
export const buttonStyle = css<StyledButtonProps>`
  ${common};
  box-sizing: border-box;
  text-decoration: none;
  background: none;
  border: none;
  display: flex;
  align-items: ${({ alignItems = "center" }) => alignItems};
  justify-content: ${({ justifyContent = "center" }) => justifyContent};
  cursor: pointer;
  position: relative;
  font-family: "Inter" !important;
  padding: ${({ theme, padding }) => padding ?? `${theme.space.S} ${theme.space.S}`};
  overflow: hidden;

  * {
    position: relative;
  }

  ${({ boxShadow }) =>
    boxShadow &&
    css`
      box-shadow: ${({ theme }) => theme.variable.boxShadow};
    `};

  ${({
    styledType,
    theme,
    width = "auto",
    height = "45px",
    fontSize = "M",
    background = "primary",
    color = "white",
    hoverColor = "white",
    hoverBackground = "primaryDarker",
    loading,
  }) =>
    styledType === "primary" &&
    css`
      position: relative;
      background: ${theme.color[background]};
      font-size: ${theme.font[fontSize]};
      font-weight: ${theme.font.normal};
      width: ${width};
      height: ${height};
      color: ${theme.color[color] ?? color};
      border-radius: ${theme.variable.borderRadius};
      transition: background 0.3s;

      svg {
        width: ${theme.font[fontSize]};
        height: ${theme.font[fontSize]};
      }

      ${!loading &&
      css`
        &:hover {
          background: ${theme.color[hoverBackground] ?? hoverBackground};
          color: ${theme.color[hoverColor] ?? hoverColor};
        }
      `}
    `}

  ${({
    styledType,
    theme,
    width = "auto",
    height = "45px",
    fontSize = "M",
    background = "primaryBackground",
    color = "primary",
    hoverColor = "white",
    hoverBackground = "primary",
    loading,
  }) =>
    styledType === "primaryBackground" &&
    css`
      position: relative;
      background: ${theme.color[background]};
      font-size: ${theme.font[fontSize]};
      font-weight: ${theme.font.normal};
      width: ${width};
      height: ${height};
      color: ${theme.color[color] ?? color};
      border-radius: ${theme.variable.borderRadius};
      transition: background 0.3s;
      border: 1px solid ${theme.color[background]};

      svg {
        width: ${theme.font[fontSize]};
        height: ${theme.font[fontSize]};
      }

      ${!loading &&
      css`
        &:hover {
          background: ${theme.color[hoverBackground] ?? hoverBackground};
          color: ${theme.color[hoverColor] ?? hoverColor};
        }
      `}
    `}

  ${({
    styledType,
    theme,
    width = "auto",
    height = "45px",
    fontSize = "M",
    color = "second",
    background = "background",
    hoverBackground = "backgroundDarker",
    hoverColor = "second",
    loading,
  }) =>
    styledType === "secondary" &&
    css`
      background: ${theme.color[background] ?? background};
      font-size: ${theme.font[fontSize]};
      font-weight: ${theme.font.normal};
      width: ${width};
      height: ${height};
      color: ${theme.color[color] ?? color};
      border-radius: ${theme.variable.borderRadius};
      transition: background 0.3s;

      svg {
        width: ${theme.font[fontSize]};
        height: ${theme.font[fontSize]};
      }

      ${!loading &&
      css`
        &:hover {
          background: ${theme.color[hoverBackground]};
          color: ${theme.color[hoverColor]};
        }
      `}
    `}

    ${({
    styledType,
    theme,
    width = "fit-content",
    color = "second",
    padding,
    background,
    loading,
  }) =>
    styledType === "third" &&
    css`
      font-size: ${theme.font.M};
      font-weight: ${theme.font.normal};
      width: ${width};
      padding: ${({ theme }) => padding ?? `${theme.space.XS} ${theme.space.S}`};
      transition: color 0.3s;
      color: ${theme.color[color] ?? color} !important;

      ${background &&
      css`
        background: ${theme.color[background] ?? background};
      `}

      ${!loading &&
      css`
        &:hover {
          color: ${theme.color.primary};
        }
      `};

      svg {
        width: ${theme.font.L};
        height: ${theme.font.L};
      }
    `}

    ${({ styledType, theme, width = "fit-content", padding = "0px", background, loading }) =>
    styledType === "fourth" &&
    css`
      font-size: ${theme.font.M};
      font-weight: ${theme.font.normal};
      color: ${theme.color.second} !important;
      width: ${width};
      padding: ${padding};
      transition: color 0.3s;

      ${background &&
      css`
        background: ${theme.color[background] ?? background};
      `}

      ${!loading &&
      css`
        &:hover {
          color: ${theme.color.primary};
        }
      `};

      svg {
        width: ${theme.font.L};
        height: ${theme.font.L};
      }
    `}

    ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      opacity: 0.5;
    `};

  ${({ loading }) =>
    loading &&
    css`
      cursor: not-allowed;
    `};
`

const StyledButton = styled.button<
  ButtonProps & {
    styledType?: string
  }
>`
  ${buttonStyle}
`
