import React from "react"
import styled, { css } from "styled-components"
import { Box } from "components/atoms/_atoms"
import { AISourceDocument } from "services/AIService"
import Icon from "components/atoms/icon"

const SearchDataTabs = ({
  data,
  tab,
  setTab,
}: {
  data?: AISourceDocument[] | null
  tab: string
  setTab: (e: string) => void
}) => {
  if (!data || data.length === 0) return <></>

  const goodLength = data?.filter(el => el.question_code === "good").length
  const badLength = data?.filter(el => el.question_code === "bad").length
  const unknownLength = data?.filter(el => el.question_code === "unknown").length

  return (
    <Wrapper id="search_tabs">
      <Option active={tab === "all"} onClick={() => setTab("all")}>
        Wszystkie wyniki ({data.length})
      </Option>
      <Option active={tab === "good"} type="good" onClick={() => setTab("good")}>
        Potwierdzające ({goodLength})
      </Option>
      <Option active={tab === "bad"} type="bad" onClick={() => setTab("bad")}>
        Przeczące ({badLength})
      </Option>
      <Option active={tab === "unknown"} type="unknown" onClick={() => setTab("unknown")}>
        Nieokreślone ({unknownLength})
      </Option>
    </Wrapper>
  )
}

export default SearchDataTabs

const Option = styled.button<{
  type?: string
  active: boolean
}>`
  border-radius: 50px;
  background: ${({ theme }) => theme.color.white};
  font-size: ${({ theme }) => theme.font.M};
  font-weight: ${({ theme }) => theme.font.normal};
  color: ${({ theme }) => theme.color.secondary};
  border: none;
  padding: ${({ theme }) => theme.space.XXS} ${({ theme }) => theme.space.S};
  box-shadow: ${({ theme }) => theme.variable.boxShadow};

  ${({ theme, active, type }) =>
    active &&
    !type &&
    css`
      background: ${theme.color.primary};
      color: ${theme.color.white};
    `}

  ${({ theme, active, type }) =>
    active &&
    type === "good" &&
    css`
      background: ${theme.color.primaryBackground};
      color: ${theme.color.primary};
    `}
  
  ${({ theme, active, type }) =>
    active &&
    type === "bad" &&
    css`
      background: ${theme.color.redBackground};
      color: ${theme.color.red};
    `}  
  
  ${({ theme, active, type }) =>
    active &&
    type === "unknown" &&
    css`
      background: ${theme.color.orangeBackground};
      color: ${theme.color.orange};
    `}
`

const Wrapper = styled(Box)`
  width: 100%;
  display: flex;
  grid-gap: ${({ theme }) => theme.space.XS};
  margin-top: ${({ theme }) => theme.space.S};
  flex-wrap: wrap;
  height: max-content;
`
