import React, { useState } from "react"
import styled, { css } from "styled-components"
import { useSelector } from "react-redux"
import { Box, Row } from "components/atoms/_atoms"
import { Label, P } from "components/atoms/typography"
import Link from "components/atoms/link"
import Tag from "components/atoms/tag"
import { formatDate } from "utils/date/date"
import { t } from "utils/translator"
import { AISourceDocument } from "services/AIService"
import { DocumentSignatureService } from "services/DocumentSignatureService"
import { IconButton } from "components/atoms/button"
import FavouriteModal from "components/modals/FavouriteModal"
import { useIsDocumentFavourite } from "store/folders"
import { RootState } from "store/store"
import SummaryBlock from "components/shared/blocks/SummaryBlock"

const SignatureBlock = ({ suggested, data }: { suggested?: boolean; data: AISourceDocument }) => {
  const { questions } = useSelector((state: RootState) => state.search)

  const isFavourite = useIsDocumentFavourite(data.metadata.id)
  const [favouriteModal, setFavouriteModal] = useState(false)

  const [fullSummary, setFullSummary] = useState(!!suggested)

  return (
    <>
      <Wrapper suggested={suggested}>
        {suggested && (
          <Box background="primary" padding="S" paddingTop="XXS" paddingBottom="XXS">
            <Label icon="logotype" color="white">
              Sugerowany wynik przez Parrot.ai
            </Label>
          </Box>
        )}
        <Content>
          <Box marginBottom="XS">
            <Row justifyContent="space-between">
              <Label icon="cv" iconColor="primary" type="secondary">
                {data.metadata.title}
              </Label>
              {data.question_type === "argument" && data.question_code === "good" && (
                <Tag
                  icon="check-circle"
                  iconColor="primary"
                  label="Dokument potwierdza tezę"
                  color="primary"
                  background="primaryBackground"
                />
              )}
            </Row>
            <TagsWrapper marginTop="XS">
              {data?.metadata.date && (
                <Tag
                  icon="clock"
                  iconColor="primary"
                  label={formatDate(data?.metadata.date)}
                  background="background"
                />
              )}
              <Tag
                icon="decision"
                iconColor="primary"
                label={t(data?.metadata.judgmentType)}
                background="background"
              />
              <Tag
                icon="court"
                iconColor="primary"
                label={t(data?.metadata.courtType)}
                background="background"
              />
            </TagsWrapper>
          </Box>
          <P fontSize="S" color="second" marginBottom="S">
            {data.content.split(" ##### ")[1]}
          </P>
          <SummaryBlock
            isOpen={suggested}
            marginBottom="S"
            status={data.summary_code}
            id={suggested ? "suggested_signature_block_summary" : ""}
          >
            {data.summary}
          </SummaryBlock>
          <ButtonsWrapper justifyContent="flex-end">
            <IconButton
              background={suggested ? "primary" : "background"}
              color={suggested ? "white" : isFavourite ? "primary" : "second"}
              borderRadius="borderRadius"
              size="45px"
              icon={isFavourite ? "heart-fill" : "heart"}
              onClick={() => setFavouriteModal(true)}
            />
            <a
              href={DocumentSignatureService.generateFileUrl(data.metadata.id)}
              target="_blank"
              download
              rel="noreferrer"
            >
              <IconButton
                background={suggested ? "primary" : "background"}
                color={suggested ? "white" : isFavourite ? "primary" : "second"}
                borderRadius="borderRadius"
                size="45px"
                icon={"save"}
              />
            </a>
            {/*<NavLink to={`signatures/${data.metadata.id}`}>*/}
            {/*  <IconButton*/}
            {/*      background={suggested ? "primary" : "background"}*/}
            {/*      color={suggested ? "white" : isFavourite ? "primary" : "second"}*/}
            {/*      borderRadius="borderRadius"*/}
            {/*      size="45px"*/}
            {/*      icon={"full-screen"}*/}
            {/*  />*/}
            {/*</NavLink>*/}
            <Link
              to={`/panel/documents/${data.metadata.id}?q=${questions.join(" \n ")}`}
              target="_blank"
              icon="eye"
              type="secondary"
              background={suggested ? "primary" : "background"}
              color={suggested ? "white" : "second"}
              hoverBackground={suggested ? "primary" : "primaryBackground"}
              hoverColor={suggested ? "white" : "primary"}
            >
              Zobacz szczegóły
            </Link>
          </ButtonsWrapper>
        </Content>
      </Wrapper>
      <FavouriteModal id={data.metadata.id} show={favouriteModal} setShow={setFavouriteModal} />
    </>
  )
}

export default SignatureBlock

const ButtonsWrapper = styled(Row)`
  grid-gap: ${({ theme }) => theme.space.XS};
`

const SummaryWrapper = styled(Box)<{
  suggested?: boolean
}>`
  display: flex;
  grid-gap: ${({ theme }) => theme.space.XS};
  padding: ${({ theme }) => theme.space.XS};
  background: ${({ theme }) => theme.color.primaryBackground};
  border-radius: ${({ theme }) => theme.variable.borderRadius};

  span {
    cursor: pointer;
  }
`

const TagsWrapper = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  grid-gap: ${({ theme }) => theme.space.XS};
`

const Content = styled(Box)`
  padding: ${({ theme }) => theme.space.S};

  .text {
  }
`

const Wrapper = styled(Box)<{
  suggested?: boolean
}>`
  background: white;
  border-radius: ${({ theme }) => theme.variable.borderRadius};
  border: 1px solid white;
  overflow: hidden;

  ${({ suggested }) =>
    suggested &&
    css`
      border-color: ${({ theme }) => theme.color.primary};
      box-shadow: ${({ theme }) => theme.variable.boxShadow};
      //background: ${({ theme }) => theme.color.primaryBackground};
    `}
`
