import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { RootState } from "store/store"
import { ChatType } from "store/chat"
import { AISourceDocument } from "services/AIService"
import { useEffect } from "react"
import introJs from "intro.js"

export const useOnboardingSearch = (data: AISourceDocument[] | null) => {
  useEffect(() => {
    if (data && data.length > 0) {
      introJs()
        .setOptions({
          dontShowAgain: true,
          dontShowAgainLabel: "Nie pokazuj ponownie",
          dontShowAgainCookie: "search-onboarding",
          nextLabel: "Dalej",
          prevLabel: "Cofnij",
          // skipLabel: "Pomiń",
          doneLabel: "Zakończ",
          steps: [
            {
              title: "Lista fraz",
              element: document.querySelector("#search_history") as HTMLElement,
              intro:
                "Dzięki dodatkowym pytaniom możesz doprecyzować wyszukiwanie. Tutaj znajdziesz pełną listę swoich fraz dla danego wyszukiwania.",
            },
            {
              title: "Czyszczenie wyszukiwania",
              element: document.querySelector("#search_form_clear") as HTMLElement,
              intro:
                "Możesz zacząć od nowa, czyszcząc aktualne wyszukiwanie, klikając poniższy przycisk.",
            },
            {
              title: "Szybka odpowiedź",
              element: document.querySelector("#search_quick_answer") as HTMLElement,
              intro:
                "Szybka odpowiedź opiera się wyłącznie na wiedzy zaczerpniętej z różnych kodeksów prawa.",
            },
            {
              title: "Wyjaśnienie dokumentu",
              element: document.querySelector("#suggested_signature_block_summary") as HTMLElement,
              intro:
                "Krótkie wyjaśnienie dokumentu umożliwia wstępną analizę, czy fragment zawiera odpowiedź na wyszukiwaną frazę.",
            },
          ],
        })
        .start()
    }
  }, [data])
}
