import React, { useState } from "react"
import styled from "styled-components"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { Box } from "components/atoms/_atoms"
import { IconButton } from "components/atoms/button"
import { Label, P } from "components/atoms/typography"
import { BaseStyles } from "types/base-styles"
import { AIService } from "services/AIService"
import { makeId } from "utils/id"
import ChatContent from "components/molecules/chat/ChatContent"
import { MessageType } from "components/molecules/chat/types"
import ChatInput from "components/molecules/chat/ChatInput"
import { makeHistory } from "utils/chat"
import Alert from "components/molecules/alert"

const CZYNNA_QUESTION =
  "Kim była strona czynna (czyli podmiot, który wniosł pozew lub występuje do sądu z żądaniem rozstrzygnięcia kwestii odpowiedzialności prawnej). Opisz w skrócie czego się domagła, jakich argumentów używała oraz na jakiej podstawie?"
const BIERNA_QUESTION =
  "Kim była strona bierną (czyli. podmiot przeciwko któremu jest skierowany pozew lub żądanie rozstrzygnięcia o odpowiedzialności prawnej). Opisz w skrócie czego się domagła, jakich argumentów używała oraz na jakiej podstawie?"
const WYROK_QUESTION =
  "Jaki był wyrok sądu. Podaj szczegółowe uzasadnienie, jakich argumentów sąd użył oraz na jakiej podstawie? Opisz w skrócie wyrok sądu."

export const ChatRow = ({
  text = "Jeśli masz pytania dotyczące dokumentu, otwórz czat z Parrot.ai",
  setShow,
  ...rest
}: {
  text?: string
  setShow?: (e: boolean) => void
}) => {
  const navigate = useNavigate()

  return (
    <ChatRowWrapper {...rest}>
      <Box flex={1}>
        <Label icon="logotype" iconColor="white" color="white">
          Chat z Parrot.ai
        </Label>
        <P small color="white" margin="0" marginTop="XS">
          {text}
        </P>
      </Box>
      <IconButton
        borderRadius="25px"
        size="25px"
        icon="arrow-right"
        background="white"
        color="primary"
        onClick={() => (setShow ? setShow(true) : navigate("/panel/chat"))}
      />
    </ChatRowWrapper>
  )
}

const DocumentChat = ({
  id,
  show,
  setShow,
  ...rest
}: {
  id: string
  show: boolean
  setShow?: (e: boolean) => void
} & BaseStyles) => {
  const dispatch = useDispatch()

  const [text, setText] = useState<string | undefined>()
  const [loading, setLoading] = useState(false)
  const [messages, setMessages] = useState<MessageType[]>([
    {
      id: "0",
      type: "ai",
      message: "Cześć! Jak mogę Ci pomóc?",
    },
  ])

  const addToChat = (items: MessageType[]) => {
    setMessages(prevState => {
      return [...prevState, ...items]
    })
  }

  const fetchSummary = async (messageId: string, message: string) => {
    try {
      setLoading(true)
      const history = makeHistory(messages)
      const response = await AIService.chat({ id, message, history })
      addToChat([
        {
          id: messageId,
          type: "ai",
          message: response.answer,
        },
      ])
    } catch (e) {
      console.log(e)
      // SentryService.error("[ERROR ProfileOfferService.getOfferApplications]:", e)
    } finally {
      setLoading(false)
    }
  }

  const handleToggleShow = (val: boolean) => {
    if (setShow) {
      setShow(val)
    }
  }

  const handleSubmit = () => {
    if (text && text !== "") {
      const id = makeId(5)
      setText("")
      addToChat([
        {
          id,
          type: "human",
          message: text,
        },
      ])

      fetchSummary(id, text)
    }
  }

  const handleQuickMessage = (val: string, question: string) => {
    const id = makeId(5)

    addToChat([
      {
        id,
        type: "human",
        message: val,
      },
    ])

    fetchSummary(id, question)
  }

  if (!show) {
    return <></>
  }

  return (
    <Wrapper {...rest}>
      <Header>
        <HeaderContent>
          <Label icon="logotype" iconColor="primary">
            Parrot.ai
          </Label>
          {setShow && (
            <IconButton
              borderRadius="25px"
              size="25px"
              icon="close"
              background="backgroundDarker"
              onClick={() => handleToggleShow(false)}
            />
          )}
        </HeaderContent>
        <Alert small type="warning">
          Parrot.ai opiera się wyłącznie na wiedzy zawartej w wybranym dokumencie.
        </Alert>
      </Header>
      <ChatContent loading={loading} messages={messages} />
      {/*{id && (*/}
      {/*  <QuickMessagesWrapper className="hide_scroll">*/}
      {/*    <QuickMessagesContent>*/}
      {/*      <QuickMessage*/}
      {/*        onClick={() =>*/}
      {/*          handleQuickMessage("Co wiesz na temat strony czynnej", CZYNNA_QUESTION)*/}
      {/*        }*/}
      {/*      >*/}
      {/*        <P fontSize="S" color="secondary" margin="0">*/}
      {/*          Dowiedz się więcej o stronie czynnej*/}
      {/*        </P>*/}
      {/*      </QuickMessage>*/}
      {/*      <QuickMessage*/}
      {/*        onClick={() =>*/}
      {/*          handleQuickMessage("Co wiesz na temat strony biernej", BIERNA_QUESTION)*/}
      {/*        }*/}
      {/*      >*/}
      {/*        <P fontSize="S" color="secondary" margin="0">*/}
      {/*          Dowiedz się więcej o stronie biernej*/}
      {/*        </P>*/}
      {/*      </QuickMessage>*/}
      {/*      <QuickMessage*/}
      {/*        onClick={() => handleQuickMessage("Co wiesz na temat wyroku", WYROK_QUESTION)}*/}
      {/*      >*/}
      {/*        <P fontSize="S" color="secondary" margin="0">*/}
      {/*          Dowiedz się więcej o wyroku*/}
      {/*        </P>*/}
      {/*      </QuickMessage>*/}
      {/*    </QuickMessagesContent>*/}
      {/*  </QuickMessagesWrapper>*/}
      {/*)}*/}
      <ChatInput loading={loading} value={text} setValue={setText} handleSubmit={handleSubmit} />
    </Wrapper>
  )
}

export default DocumentChat

const ChatRowWrapper = styled(Box)`
  display: flex;
  padding: ${({ theme }) => theme.space.S};
  grid-gap: ${({ theme }) => theme.space.S};
  background: ${({ theme }) => theme.color.backgroundGradient};
  border-radius: ${({ theme }) => theme.variable.borderRadius};
  align-items: center;
`

const QuickMessage = styled(Box)`
  background: ${({ theme }) => theme.color.background};
  border: 1px solid ${({ theme }) => theme.color.backgroundDarker};
  width: max-content;
  padding: ${({ theme }) => theme.space.XXS} ${({ theme }) => theme.space.XS};
  border-radius: 50px;
  cursor: pointer;
`

const QuickMessagesContent = styled(Box)`
  display: flex;
  flex-wrap: nowrap;
  grid-gap: ${({ theme }) => theme.space.XS};
  width: max-content;
`

const QuickMessagesWrapper = styled(Box)`
  border-top: 1px solid ${({ theme }) => theme.color.backgroundDarker};
  padding: ${({ theme }) => theme.space.XS};
  overflow: scroll;
`

const HeaderContent = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Header = styled(Box)`
  display: flex;
  flex-direction: column;
  grid-gap: ${({ theme }) => theme.space.XS};
  padding: ${({ theme }) => theme.space.XS} ${({ theme }) => theme.space.S};
  border-bottom: 1px solid ${({ theme }) => theme.color.backgroundDarker};
`

const Wrapper = styled(Box)`
  height: ${({ height }) => height ?? "100%"};
  width: ${({ width }) => width ?? "400px"};
  background: ${({ theme }) => theme.color.white};
  border: 1px solid ${({ theme }) => theme.color.backgroundDarker};
  border-radius: ${({ theme }) => theme.variable.borderRadius};
  display: flex;
  flex-direction: column;
  overflow: hidden;
`
