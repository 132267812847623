import React from "react"
import styled from "styled-components"
import Modal from "components/molecules/modal"
import { P } from "components/atoms/typography"
import { AISourceType } from "services/AIService"

const MessageSourceModal = ({
  data,
  setState,
}: {
  data: AISourceType | null
  setState: (e: null) => void
}) => {
  return (
    <Modal
      label={data?.metadata.title}
      title={
        data
          ? `${data.metadata.artStr}${data.metadata.parStr ? ` ${data.metadata.parStr}` : ""}`
          : ""
      }
      show={!!data}
      setShow={() => setState(null)}
    >
      <StyledP>{data?.page_content.split("#####")[1]}</StyledP>
    </Modal>
  )
}

export default MessageSourceModal

const StyledP = styled(P)`
  white-space: normal;
`
