import axios from "axios"

export const API_URL = process.env.REACT_APP_API_AI_URL

const axiosInstance = axios.create({
  timeout: 1000000, // 10 sekund
})

interface Root<T> {
  data: T
}

export interface AISourceType {
  page_content: string
  metadata: {
    id: string
    title: string
    artStr: string
    parStr: string
    type: string
  }
}

export interface AISearchAnswerResponse {
  message: string
  source_documents: AISourceType[]
}

export interface AISearchResponse {
  questions: string[]
  answer: AISearchAnswerResponse
  results: AISourceDocument[]
}

export interface AISourceDocument {
  id: string
  score: number
  content: string
  metadata: Metadata
  summary: string
  summary_code: string
  question_type: string
  question_code: string
}

interface Metadata {
  id: string
  title: string
  type: string
  titleUpper: string
  courtType: string
  courtTypeStr: string
  judgmentType: string
  judgmentTypeStr: string
  date: string
  datetime: string
  divisionId: string
  divisionCategory: string
  divisionCode: string
  divisionType: string
  divisionName: string
  divisionCourtId: string
  divisionCourtCode: string
  divisionCourtName: string
  divisionCourtType: string
  divisionChamberId: string
  divisionChamberName: string
  judges: string[]
  sourceId: string
  sourceName: string
  keywords: string[]
  legalBases: string[]
}

const summaryOrder = ["success", "mixed", "error"]
const questionTypeOrder = ["argument", "question"]
const questionCodeOrder = ["good", "bad", "unknown"]

const search = async (str: string[], ids: string[]): Promise<AISearchResponse> => {
  const { data } = await axiosInstance.post<null, Root<AISearchResponse>>(`${API_URL}/search`, {
    q: str,
    ids,
  })

  const results = data.results
    .filter(el => el.metadata.type === "text")
    .sort((a, b) => {
      const summaryComparison =
        summaryOrder.indexOf(a.summary_code) - summaryOrder.indexOf(b.summary_code)

      if (summaryComparison === 0) {
        const questionTypeComparison =
          questionTypeOrder.indexOf(a.question_type) - questionTypeOrder.indexOf(b.question_type)

        if (questionTypeComparison === 0) {
          return (
            questionCodeOrder.indexOf(a.question_type) - questionCodeOrder.indexOf(b.question_type)
          )
        }
        return questionTypeComparison
      }

      return summaryComparison
    })

  return {
    questions: data.questions,
    results: results,
    answer: data.answer,
  }
}

const chat = async ({
  message,
  history = [],
  types,
  ids,
  id,
}: {
  message: string
  history?: string[][]
  types?: string[]
  ids?: string[]
  id?: string
}): Promise<{
  answer: string
  question: string
  source_documents: AISourceType[]
}> => {
  const { data } = await axiosInstance.post<null, any>(`${API_URL}/chat`, {
    message,
    history,
    types,
    id,
    ids,
  })

  return data
}

const quickAnswer = async ({ id, message }: { id: string; message: string }): Promise<string> => {
  const { data } = await axiosInstance.post<
    null,
    Root<{
      answer: string
      source_documents: {
        page_content: string
      }[]
    }>
  >(`${API_URL}/quick-answer`, {
    id,
    message,
  })

  return data.answer
}

const funFact = async (): Promise<string> => {
  const { data } = await axiosInstance.get<null, Root<string>>(`${API_URL}/fun-fact`)

  return data
}

export const AIService = {
  search,
  chat,
  quickAnswer,
  funFact,
}
