import React, { useState } from "react"
import styled from "styled-components"
import { useDispatch, useSelector } from "react-redux"
import AddFolderModal from "./AddFolderModal"
import Modal from "components/molecules/modal/Modal"
import { RootState } from "store/store"
import { UserFolderType } from "services/types"
import { Box } from "components/atoms/_atoms"
import { Label } from "components/atoms/typography"
import Button from "components/atoms/button/Button"
import { addDocumentToFolder, deleteDocumentToFolder, useFolder, useFolders } from "store/folders"
import { IconButton } from "components/atoms/button"
import { SentryService, UserFolderService } from "services"

const ToggleFavouriteModalRow = ({ id, data }: { id: string; data: UserFolderType }) => {
  const folder = useFolder(data.id)
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)

  const handleAdd = async () => {
    try {
      setLoading(true)
      await UserFolderService.addToFolder(data.id, id)
      dispatch(
        addDocumentToFolder({
          documentId: id,
          folderId: data.id,
        }),
      )
    } catch (e) {
      SentryService.error("[ERROR UserFolderService.addToFolder]:", e)
    } finally {
      setLoading(false)
    }
  }

  const handleDelete = async () => {
    try {
      setLoading(true)
      await UserFolderService.deleteToFolder(data.id, id)
      dispatch(
        deleteDocumentToFolder({
          documentId: id,
          folderId: data.id,
        }),
      )
    } catch (e) {
      SentryService.error("[ERROR UserFolderService.deleteDocumentToFolder]:", e)
    } finally {
      setLoading(false)
    }
  }

  return (
    <RowWrapper>
      <Label>{data.name}</Label>
      {!folder.documentIds.includes(id) ? (
        <IconButton
          borderRadius="borderRadius"
          background="backgroundDarker"
          size="45px"
          icon="plus"
          loading={loading}
          onClick={handleAdd}
        />
      ) : (
        <IconButton
          borderRadius="borderRadius"
          background="backgroundDarker"
          size="45px"
          icon="minus"
          loading={loading}
          onClick={handleDelete}
        />
      )}
    </RowWrapper>
  )
}

const ToggleFavouriteModal = ({ id }: { id: string }) => {
  const { folders } = useFolders()

  return (
    <RowsWrapper>
      {folders.map((folder, index) => (
        <ToggleFavouriteModalRow id={id} key={index} data={folder} />
      ))}
    </RowsWrapper>
  )
}

const FavouriteModal = ({
  id,
  show,
  setShow,
}: {
  id: string
  show: boolean
  setShow: (e: boolean) => void
}) => {
  const [addFolder, setAddFolder] = useState(false)

  return (
    <>
      <Modal
        icon="heart"
        show={show}
        setShow={setShow}
        title="Lista folderów"
        text="Dodaj lub usuń dokument z folderu."
      >
        <>
          <ToggleFavouriteModal id={id} />
          <Button margin="0 auto" marginTop="XS" type="third" onClick={() => setShow(false)}>
            Zamknij
          </Button>
        </>
      </Modal>
      <AddFolderModal show={addFolder} setShow={setAddFolder} />
    </>
  )
}

export default FavouriteModal

const RowsWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  grid-gap: ${({ theme }) => theme.space.XS};
`

const RowWrapper = styled(Box)`
  padding: ${({ theme }) => theme.space.XXS};
  padding-left: ${({ theme }) => theme.space.S};
  border-radius: ${({ theme }) => theme.variable.borderRadius};
  background: ${({ theme }) => theme.color.background};
  display: flex;
  justify-content: space-between;
  align-items: center;
`
