import React, { useState } from "react"
import styled from "styled-components"
import { useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"
import { Box, Row } from "components/atoms/_atoms"
import FavouritesFolderBlock from "components/shared/blocks/FavouritesFolderBlock"
import Button from "components/atoms/button"
import { RootState } from "store/store"
import LoaderBlock from "components/shared/blocks/LoaderBlock"
import AddFolderModal from "components/modals/AddFolderModal"
import { P } from "components/atoms/typography"
import { useFolders } from "store/folders"

const FavouritesView = () => {
  const navigate = useNavigate()
  const { loading, folders } = useFolders()

  const [addFolder, setAddFolder] = useState(false)

  return (
    <>
      <Wrapper>
        <Row justifyContent="flex-end">
          <Button onClick={() => setAddFolder(true)} icon="plus">
            Dodaj folder
          </Button>
        </Row>
        {!loading && folders.length === 0 && (
          <Row marginTop="M" justifyContent="center">
            <P small>Brak folderów - dodaj swój pierwszy folder.</P>
          </Row>
        )}
        <Content>
          {loading && (
            <>
              <LoaderBlock height="140px" />
              <LoaderBlock height="140px" />
              <LoaderBlock height="140px" />
              <LoaderBlock height="140px" />
            </>
          )}
          {!loading && (
            <>
              {/*<FavouritesFolderBlock*/}
              {/*  primary*/}
              {/*  data={{*/}
              {/*    id: "all",*/}
              {/*    name: "Wszystkie dokumenty",*/}
              {/*    count: 22,*/}
              {/*    documentIds: []*/}
              {/*  }}*/}
              {/*/>*/}
              {folders.map((el, index) => (
                <FavouritesFolderBlock key={index} data={el} />
              ))}
            </>
          )}
        </Content>
      </Wrapper>
      <AddFolderModal show={addFolder} setShow={setAddFolder} />
    </>
  )
}

export default FavouritesView

const Content = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: ${({ theme }) => theme.space.S};
  margin-top: ${({ theme }) => theme.space.S};
`

const Wrapper = styled(Box)``
