import React, { useState } from "react"
import styled from "styled-components"
import { Box } from "components/atoms/_atoms"
import { BaseStyles } from "types/base-styles"
import Icon from "components/atoms/icon"
import { Label, pStyles, P } from "components/atoms/typography"

const SummaryBlock = ({
  id,
  icon = "summary",
  label = "Streszczenie",
  children,
  status,
  isOpen = false,
  ...rest
}: {
  id?: string
  icon?: string
  label?: string
  children: string
  status: string
  isOpen?: boolean
} & BaseStyles) => {
  const [fullSummary, setFullSummary] = useState(isOpen)

  let color = "primary"
  if (status === "mixed") {
    color = "orange"
  } else if (status === "error") {
    color = "red"
  }

  return (
    <SummaryWrapper color={color} id={id} {...rest}>
      <Icon icon={icon} size="L" color={color} />
      <Box>
        <Label color={color} fontSize="XS" marginBottom="XS" fontWeight="bold">
          {label}
        </Label>
        <P fontSize="S" margin="0" color={color} spanColor={color}>
          {fullSummary ? children : `${children.substring(0, 70)}...`}
          {fullSummary ? (
            <>
              <br />
              <br />
              <span onClick={() => setFullSummary(!fullSummary)}>Zobacz mniej</span>
            </>
          ) : (
            <span onClick={() => setFullSummary(!fullSummary)}> Zobacz więcej</span>
          )}
        </P>
      </Box>
    </SummaryWrapper>
  )
}

export default SummaryBlock

const Content = styled(Box)`
  p {
    ${pStyles};
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.font.XXS};
    margin: 0 !important;
    margin-top: ${({ theme }) => theme.font.XXS} !important;
    padding-left: 15px;

    ol,
    li {
      cursor: pointer;
      margin: 0 !important;
      ${pStyles};
      font-size: ${({ theme }) => theme.font.S};
      color: ${({ theme }) => theme.color.primary};

      &:hover:not(:has(*:hover)) {
        text-decoration: underline;
      }
    }
  }
`

const SummaryWrapper = styled(Box)<{
  color?: string
  suggested?: boolean
}>`
  display: flex;
  grid-gap: ${({ theme }) => theme.space.XS};
  padding: ${({ theme }) => theme.space.XS};
  background: ${({ theme, color }) => theme.color[`${color}Background`]};
  border-radius: ${({ theme }) => theme.variable.borderRadius};

  span {
    cursor: pointer;
  }
`
