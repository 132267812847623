import React, { useState } from "react"
import styled from "styled-components"
import SummaryBlock from "components/shared/blocks/SummaryBlock"
import { AISearchAnswerResponse, AISourceType } from "services/AIService"
import Modal from "components/molecules/modal"
import { P } from "components/atoms/typography"
import { Box } from "components/atoms/_atoms"
import MessageSourceModal from "components/modals/MessageSourceModal"
import ChatMessageSource from "components/molecules/chat/ChatMessageSource"
import Button from "components/atoms/button"

const SearchAnswer = ({
  loading,
  answer,
}: {
  loading: boolean
  answer: AISearchAnswerResponse | null
}) => {
  const [showSource, setShowSource] = useState<null | AISourceType>(null)

  const sources = answer?.source_documents
  const hasAnswer = !!answer && !loading

  if (!hasAnswer) return <></>

  return (
    <>
      <Wrapper>
        <SummaryBlock
          id="search_quick_answer"
          isOpen={true}
          icon="logotype"
          label="Parrot.ai"
          height="max-content"
          status="success"
        >
          {answer?.message}
        </SummaryBlock>
        {sources && sources.length > 0 && (
          <MessageSources>
            {sources.map((source, index) => (
              <ChatMessageSource
                type="primaryBackground"
                key={`${source.metadata.id}-${index}`}
                data={source}
                onClick={() => setShowSource(source)}
              />
            ))}
          </MessageSources>
        )}
      </Wrapper>
      <MessageSourceModal data={showSource} setState={setShowSource} />
    </>
  )
}

export default SearchAnswer

const Wrapper = styled(Box)``

const MessageSources = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  grid-gap: ${({ theme }) => theme.space.XS};
  margin-top: ${({ theme }) => theme.space.S};
`
